.button {
  align-items: center;
  background-color: var(--color-lime-green);
  border-radius: var(--border-radius-full);
  border: 0.1rem solid var(--color-lime-green);
  color: var(--color-darkblue);
  cursor: pointer;
  display: inline-flex;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-m);
  justify-content: center;
  line-height: var(--line-height-s);
  padding: 1.2rem 1.8rem;
  text-decoration: none;
  transition: all 200ms ease-out;
  width: auto;

  &:hover,
  &:focus {
    box-shadow: 0 0.4rem 0.4rem rgb(0 0 0 / 5%);
    background-color: var(--color-button-primary-hover);
  }

  &.disabled {
    background-color: var(--color-gray);
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: var(--color-gray);

      & > svg {
        transition: none;
        transform: none;
      }
    }
  }

  &.translateIcon > svg {
    transform: translateX(0.2rem);
  }

  &:hover > svg {
    transition: transform 100ms ease-in-out;
  }

  & > svg {
    height: var(--font-size-m);
    width: var(--font-size-m);
  }

  & > :not(:last-child) {
    margin-right: 0.8rem;
  }

  & a,
  & span {
    font-family: var(--font-family-semibold);
    white-space: nowrap;
  }

  &.bold {
    font-weight: var(--font-weight-bold);
  }
}

.secondary {
  background-color: var(--color-gray-basic);
  border: 0.1rem solid var(--color-darkblue);

  &:hover,
  &:focus {
    background-color: var(--color-gray-basic);
    box-shadow: 0 0 0 0.2rem var(--color-darkblue);
  }
}

.secondaryBorderless {
  background-color: var(--color-gray-basic);
  border: none;

  &:hover,
  &:focus {
    background-color: var(--color-gray-basic);
    box-shadow: 0 0 0 0.2rem var(--color-darkblue);
  }
}

.discrete {
  background: none;
  font-size: var(--font-size-xs);
  line-height: var(--line-height-s);
  border: none;
  color: var(--color-darkblue);

  &:hover,
  &:focus {
    background: transparent;
    box-shadow: none;
  }
}

.plain {
  background: none;
  border: none;
  color: var(--color-darkblue);
  padding: 0;

  & svg {
    height: var(--font-size-s);
  }

  & span {
    font-family: var(--font-family);
    font-size: var(--font-size-s);
  }

  &:hover,
  &:focus {
    background: var(--color-gray-4);
    box-shadow: none;
  }
}

.plainRed {
  background: none;
  border: none;
  padding: 0;
  color: var(--color-red);

  & svg {
    height: var(--font-size-s);
  }

  & span {
    font-family: var(--font-family);
    font-size: var(--font-size-s);
  }

  &:hover,
  &:focus {
    background: var(--color-gray-4);
    box-shadow: none;
  }
}

.inverted {
  background-color: var(--color-white);
  color: var(--color-darkblue);

  &:hover,
  &:focus {
    background-color: var(--color-gray);
  }
}
